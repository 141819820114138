import React from "react"
import styles from "./solve-score-css-module.module.scss"
import solvScore from "../../assets/solvScore.png"
import { Layout, SEO } from "../../components"

const SolvScore = () => {
  return (
    <>
      <Layout>
        {/* <SEO 
            title="Solv B2B platform| E-Commerce Marketplace| Credit for MSME" 
            description="Solv is a B2B e-commerce marketplace for SMEs. It facilitates commerce while easing access to finance & business services through a seamless digital experience."
            /> */}
        <section className={styles.container}>
          <div className={styles.subHeading}>Build digital trust</div>
          <div className={[styles.heading, "heading1SemiBold"].join(" ")}>
            How to read your SOLV score?
          </div>
          <img src={solvScore} alt="logo" />
          <div className={styles.description}>
            Your SOLV score reflects your trustworthiness and credit health. It
            ranges from 1 to 5 where higher the score stands as best in class
            among other SMEs. Score more than 4 is considered as very strong
            score that brings confidence in other parties to do business with.
            It also stands as strong score on your approval rate of your credit
            requirement for business needs. SOLV Score is based on the
            documentation provided, alternate data like your transactional SMS
            messages, email, Google rating etc., transactions history on SOLV
            app, order fulfilment, and multiple other criteria.
          </div>
          <div className={[styles.heading, "heading1SemiBold"].join(" ")}>
            Build digital trust with SOLV score
          </div>
          <div className={styles.description}>
            An SME centric trust-based score to create better engagement and
            transparent transaction experience on SOLV platform. It helps to
            connect with more relevant and trustworthy parties from the
            marketplace. SOLV score also aids in better credit decisioning and
            achieving financial inclusion.
          </div>
        </section>
      </Layout>
    </>
  )
}

export default SolvScore
